
@keyframes count {
  0% {transform: scale(3);}
  100% {transform: scale(1);}
}

.readygoText {
  -webkit-animation: count 0.1s cubic-bezier(0.1, 0.1, 1, 1) 1;
  animation: count 0.1s cubic-bezier(0.1, 0.1, 1, 1) 1;
}
