.container {
  display: flex;
  flex-direction: column;
  height: 100dvh; /* 使容器占满整个视口高度 */
}

.header {
  z-index: 1000;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* 使内容区域占据剩余空间 */
  overflow-y: auto; /* 允许内容区域滚动 */
}

.contentArea {
    padding: 16px;
    height: 100%;
}

.footer a {
  border-radius: 8px !important;  /* 设置圆角矩形 */
}

.footer svg {
  padding: 0.1em !important;
}


.active {
  color: var(--mui-palette-primary-contrastText) !important;
  background-color: var(--mui-palette-primary-main) !important;
  border-radius: 8px !important; /* 圆角效果 */
}

/* 永远占据父节点的所有空间，不管父节点是否还有其他子节点 */
.loadingMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;   
    justify-content: center;
    align-items: center;   
    z-index: 1000; /* 确保遮罩层在其他元素之上 */   
}   