.listItem {
  overflow: hidden;
}

.listItem:hover {
    background-color: #f0f0f0;
}

.word {
  width: 12em;
  font-weight: bold !important;
}

.alternative {
  font-size: 0.8em !important;
  font-weight: bold;
  color: #999;
}

.phonetic {
  color: olive;
  white-space: nowrap;
  padding-right: 16px;
}

.translation {
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
