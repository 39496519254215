.container {
  height: 100dvh;
  width: 100%;
  overflow: hidden;
}

.navigationButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.navigationButton:hover {
  opacity: 1;
}

.navigationButton:disabled {
  opacity: 0.3;
}

.contentBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  z-index: 1;
}

.alternative {
  color: gray;
}   

.pronunciationBox {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.pronunciationLabel {
  background-color: #66668B;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;  
  vertical-align: middle;
}

.pronunciationText {
  color: darkolivegreen;
  padding-left: 0.4em;
  font-family: 'Arial Unicode MS', 'Lucida Sans Unicode', sans-serif;
}

.translationBox {
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.translationInnerBox {
  width: auto;
  min-height: 4em;
  min-width: 40%;
  max-width: clamp(20rem, 70%, 50rem);
  background-color: #f0f0f0;
  display: inline-block;
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease-out;
  animation: slideDown 0.3s ease-out;
  transform-origin: top;
}

.translationEntry {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
  white-space: nowrap;
  text-align: left;
}

.translationEntry:last-child {
  margin-bottom: 0;
}

.partOfSpeech {
  color: darkorange;
  font-weight: bold !important;
  text-align: right;
  flex-shrink: 0;
}

.translationText {
  color: #666 !important;
  white-space: normal;
  word-break: break-word;
}

.clickArea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.clickPad {
  flex: 1;
  height: 100%;
  transition: background-color 0.3s;
}

.controlBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.playPauseButton {
  color: white;
}

.modeHint {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  white-space: nowrap;
  background-color: lightgrey; 
}

.fail {
  background-color: rgb(240, 76, 76) !important;
  border-color: rgb(195, 79, 79) !important;
}
.pass {
  border-color: rgb(67, 87, 33) !important;
  background-color: rgb(33, 116, 33) !important;
}

.modeHint svg {
  width: 2em;
  height: 2em;
  animation: fadeInOut 2s ease-in-out forwards; /* 使用 forwards 保持最后状态 */
}

.modeHintE2C {
  border-color: rgb(80, 50, 119);
  background-color: rgb(124, 76, 187);
}

.modeHintC2E {
  border-color: rgb(174, 108, 28);
  background-color: rgb(227, 145, 38);
}

.modeHintSpell {
  border-color: rgb(45, 72, 118);
  background-color: rgb(70, 102, 204);
}

.countdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4em;
  z-index: 100;
} 

.statusBarLeft {
  flex: 1;
  flex-grow: 0;
} 

.statusBarCenter {
  flex: 8;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}   

.statusBarRight {
  flex: 1;
  display: flex;
  flex-grow: 0;
  min-width: 2em;
  justify-content: flex-end;
}   

@keyframes fadeInOut {
  0% { opacity: 0; transform: scale(0.8); }
  25% { opacity: 1; transform: scale(1.1); }
  75% { opacity: 1; transform: scale(1.1); }
  100% { opacity: 0; transform: scale(1); }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}
