/* client/src/pages/PlanExam.css */

.status {
    margin-bottom: 50px;
    color: "olive";
}

.result {
    text-align: center;
    margin-bottom: 20px;
}
