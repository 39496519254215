.proficiencyBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2em;
  border-radius: 0.9em;
  height: 1.8em;
  padding: 0 1.8em;
  color: white;
  white-space: nowrap;
  background-color: lightgrey; 
  border: 0.16em solid darkgrey;
}

.proficiencyBar.green {
  background-color: rgb(2, 156, 2);
  border-color: rgb(1, 108, 1);
}

.proficiencyBar.blue {
  background-color: rgb(162, 162, 3);
  border-color: rgb(122, 122, 2);
}

.proficiencyBar.orange {
  background-color: rgb(243, 148, 54);
  border-color: rgb(175, 98, 4);
}

.proficiencyBar.red {
  background-color: rgb(207, 70, 70);
  border-color: rgb(161, 19, 19);
}
